import { KioskDetail } from 'actions/V3/kiosks';
import { Icon } from 'antd';
import { t } from 'i18next';
import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import 'summernote';
import 'summernote/dist/summernote-lite.css';
import 'summernote/dist/summernote-lite.js';


type P = {
  close: () => void;
  kiosk: KioskDetail;
  modalOpen: boolean;
  edition: boolean;
  updateKioskData?: (key: string, value: any) => void;
  modalContent: string;
};

const ModalInfo: React.SFC<P> = React.memo((props) => {
  const { close, kiosk, modalOpen, edition, updateKioskData, modalContent } = props;

  const [isEditing, setIsEditing] = useState(edition);
  const [kioskInfo, setKioskInfo] = useState(
    modalContent === 'kiosk_information' ? kiosk.kiosk_information : kiosk.location_information
  );

  useEffect(() => {
    if (isEditing) {
      $('#summernote').summernote({
        placeholder: 'Write here',
        tabsize: 2,
        height: 500,
        focus: true,
        toolbar: [
          ['style', ['bold', 'italic', 'underline', 'clear']],
          ['font', ['strikethrough', 'superscript', 'subscript']],
          ['fontsize', ['fontsize']],
          ['color', ['color']],
          ['para', ['ul', 'ol', 'paragraph']],
          ['table', ['table']],
          ['insert', ['link']],
          ['view', ['fullscreen', 'codeview', 'help']]
        ],
        callbacks: {
          onChange: (content) => {
            setKioskInfo(content);
          }
        }
      });
    } else {
      $('#summernote').summernote('destroy');
    }
  }, [isEditing]);

  const handleUpdate = () => {
    if (updateKioskData) {
      updateKioskData(modalContent, kioskInfo);
    }

    setIsEditing(false);
    close();
  };

  return (
    <CSSTransition classNames='appear' timeout={300} unmountOnExit={true} in={modalOpen === true}>
      <div className='kiosk-modal-info'>
        <div className='kiosk-page--close-btn' onClick={close}>
          <Icon type='close' theme='outlined' />
        </div>
        <div className='kiosk-modal-info-content'>
          {isEditing ? (
            <div>
              <h2 style={{ textAlign: 'center', paddingBottom: '15px' }}> {modalContent === 'kiosk_information' ? t('kiosk.edit kiosk information') : t('kiosk.edit location information')}</h2>
              <div id='summernote' dangerouslySetInnerHTML={{ __html: kioskInfo }} />
            </div>
          ) : (
            <div dangerouslySetInnerHTML={{ __html: kioskInfo }} />
          )}
        </div>
        <div className='kiosk-modal-info-footer'>
          {!isEditing ? (
            <button className='ant-btn ant-btn-primary' onClick={close}>
              {t('Close')}
            </button>
          ) : (
            <div>
              <button className='ant-btn ant-btn-danger' onClick={close}>
                {t('Cancel')}
              </button>
              <button className='ant-btn ant-btn-primary' style={{ marginLeft: '20px' }} onClick={handleUpdate}>
                {t('indicator.preSaveBtn')}
              </button>
            </div>
          )}
        </div>
      </div>
    </CSSTransition>
  );
});

export default ModalInfo;
