import axios from 'axios'
import { logging } from 'utils/loggers'

const { host, hostname } = window.location
const base = host || hostname

type Res = {
  data?: {
    assets: string[],
    services: string[],
    endpoints: string[]
  },
  meta: {
    code: number,
    message: string
  }
}

/**
 * @deprecated Use defined environment variable instead!
 */
export const hostUrl = (url: string): string => {
  return `https://${base.includes('testing') ? 'testing.qlear.io' : 'qlear.io'}${url}`
}

export const V2Url = () => `https://${base.includes('testing') ? 'v2-testing.qlear.io' : 'v2.qlear.io'}`

/**
 * @deprecated Use defined environment variable instead!
 */
export const blogUrl = (): string => {
  return `https://${base.includes('testing') ? 'blog-testing' : 'blog'}.qlear.io`
}

/**
 * Retrieve the base URL for the API.
 */
export const apiPath = async () => {
  if (process.env.REACT_APP_QLEAR_API_URL) {
    return process.env.REACT_APP_QLEAR_API_URL
  }

  logging.error('WARNING: USING HORRIBLE CODE TO DETERMINE THE API URL!!!')

  const inPreview = base.includes('preview')
  const inTesting = base.includes('testing')

  let metaPath = inPreview || inTesting ? 'https://meta.qlear.io/' : 'https://meta.qlear.io/'

  if (process.env.NODE_ENV !== 'production') {
    metaPath = 'https://meta.qlear.io'
  }

  try {
    const {
      data: { data }
    } = await axios.get<Res>(metaPath, {
      timeout: 3000
    })
    if (inTesting) {
      console.warn('list of callable api', data)
    }
  } catch (error) {
    logging.error('cannot get list of callable api', error)
  }

  const prefix = base.includes('global') ? 'api-global' : 'api'
  if (process.env.NODE_ENV === 'development') {
    return `https://${prefix}-testing.qlear.io`
  }
  const inBeta = base.includes('beta')
  if (inBeta) {
    return `https://${prefix}.qlear.io`
  }

  return inPreview || inTesting ? `https://${prefix}-testing.qlear.io` : `https://${prefix}.qlear.io`
}
