import { requestNotificationConfigV3 } from 'actions/V3/notificationConfig';
import {
  setUserConfig
} from 'actions/V3/userConfig';
import { Button, notification, Radio, Select } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio/interface';
import CustomSelect from 'components/Custom/Select';
import Table from 'components/Custom/Table';
import { P, State, StateProps } from 'containers/NotificationSetting/type';
import { t } from 'i18next';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { apiV3 } from 'utils/api';
import { translations } from 'utils/constant';
import './index.css';

const Option = Select.Option;
const Feqs = [
  {
    title: (t('Notification.Hourly') + ' ( ' + t('Notification.Time') + ' [+8:00] )'),
    key: 'hourly'
  },
  {
    title: (t('Notification.Daily') + ' ( ' + t('Notification.Time daily') + '10:00 [+8:00] )'),
    key: 'daily'
  },
  {
    title: (t('Notification.Weekly') + ' ( ' + t('Notification.monday') + '10:00 [+8:00] )'),
    key: 'weekly'
  }
];
const BaseSetting = [
  {
    title: t('Notification.Web Only'),
    key: 'web'
  },
  {
    title: t('Notification.Email Only'),
    key: 'email'
  },
  {
    title: t('Notification.Web and Email'),
    key: 'web_and_email'
  }
];
const Connectivity = [
  {
    title: t('Notification.Disabled'),
    key: ''
  }
].concat(BaseSetting);

const category = [
  {
    label: t('Location'),
    value: 'location'
  },
  {
    label: t('Collection'),
    value: 'collection'
  },
  {
    label: t('Indicator'),
    value: 'indicator'
  }
];

class NotificationSetting extends React.PureComponent<P, State> {
  state: State = {
    radioValue: 'location',
    notification_period: '',
    notification_channel_status: 'web',
    notification_channel_data_source: 'web',
    conditionsTable: [],
    statusTable: [],
    conditionalActive: false
  };
  componentDidMount() {
    this._requestNotificationConfigs();
  }

  navToCenter = () => {
    const { history } = this.props;
    history.push('/notification');
  };

  // TODO action set user config
  updateConfig = (v, field) => {
    const { dispatch, userConfig } = this.props;
    apiV3('/notification_configs/options', 'post', {
      [field]: v
    }).then(() => {
      const newData = Object.assign({}, userConfig, { [field]: v })
      dispatch(
        setUserConfig(newData)
      )
      notification.success({
        message: t('Notification.Settings Updated Success')
      })
    });
  };

  _requestNotificationConfigs = (query = {}) => {
    const { dispatch } = this.props;
    const { radioValue } = this.state;
    dispatch(
      requestNotificationConfigV3({
        category: radioValue,
        size: 10,
        show: false,
        ...query
      })
    );
  };

  handleTable1Change = (page, size) => {
    const { query } = this.notificationData();
    this._requestNotificationConfigs({ ...query, page, size });
  };

  geneTable1Columns = () => {
    const { radioValue } = this.state;
    const showLocation = ['location', 'collection'].includes(radioValue);
    const showCollection = radioValue === 'collection';
    const showDatasource = radioValue === 'indicator';
    return [
      {
        title: t('Notification.Workspace'),
        dataIndex: 'workspace_name',
        key: 'workspace_name',
        render: text => text || '--'
      },
      {
        title: t('Notification.Locations'),
        dataIndex: 'location_name',
        key: 'location_name',
        disabled: !showLocation,
        render: text => text || '--'
      },
      {
        title: t('Notification.Collections'),
        dataIndex: 'collection_name',
        key: 'collection_name',
        disabled: !showCollection,
        render: text => text || '--'
      },
      {
        title: t('Notification.DatasourceName'),
        dataIndex: 'data_source_name',
        key: 'data_source_name',
        disabled: !showDatasource,
        render: text => text || '--'
      },
      {
        title: t('Notification.IndicatorName'),
        dataIndex: 'data_channel_name',
        key: 'data_channel_name'
      },
      {
        title: t('Notification.condications'),
        dataIndex: 'cond',
        key: 'cond',
        render: (text, record) =>
          `${translations[record.type]} = ${record.value}`
      }
    ].filter(v => !v.disabled);
  };

  onChange = (obj, field, tof) => {
    apiV3('/notification_configs', 'post', {
      workspace_id: obj.id,
      category: 'workspace',
      workspace_config: {
        [field]: tof
      }
    });
  };

  onRow1 = record => ({
    onClick: () => {
      const { history } = this.props;
      let url = '/';
      const state = { readingLimit: true };
      switch (record.category) {
        case 'indicator':
          url = `/workspaces/${record.workspace_id}/indicators/${record.indicator_id}`;
          history.push(url, { state });
          break;
        case 'location':
        case 'collection':
          history.push(
            `/workspaces/${record.workspace_id}/locations/${record.location_id}`
          );
          break;
        default:
          break;
      }
    }
  });

  rowClassGen = record => {
    const tof = ['indicator', 'location'].includes(record.category);
    return tof ? 'noti-setting-clickable' : '';
  };

  notificationData = () => {
    const { radioValue } = this.state;
    const { notificationConfig } = this.props;
    return notificationConfig[radioValue];
  };

  tableData = () => {
    const limit = this.notificationData();
    return {
      table: limit.data.map((v, i) => ({ ...v, key: `limit-table${i}` })),
      pagination: limit.meta
        ? {
          hideOnSinglePage: true,
          showQuickJumper: true,
          onChange: this.handleTable1Change,
          current: limit.meta.current_page,
          total: limit.meta.total_count,
          showTotal: () => t('showTotal', { total: limit.meta!.total_count }),
          defaultPageSize: 10
        }
        : {}
    };
  };

  radioChange = (e: RadioChangeEvent) => {
    const v = e.target.value;
    const config = this.props.notificationConfig;
    const ids = ['location_id', 'collection_id', 'indicator_id'];
    const shouldRequest =
      config[v] &&
      (!config[v].meta ||
        Object.keys(config[v].query).some(k => ids.includes(k)));
    this.setState(
      { radioValue: v },
      shouldRequest ? this._requestNotificationConfigs : undefined
    );
  };

  render() {
    const { userConfig } = this.props;

    const { radioValue } = this.state;
    const notificationTable = this.tableData();
    return (
      <div className='noti-content location-inner'>
        <div className='noti-header-bar'>
          <div className='noti-header-left'>{t('Notification.Settings')}</div>
          <div className='noti-header-right'>
            <Button
              ghost={true}
              type='primary'
              icon='bell'
              onClick={this.navToCenter}
            >
              {t('Notification.Center')}
            </Button>
          </div>
        </div>
        <div className='noti-setting-line' />
        <div className='noti-setting-wrap'>
          <div className='noti-setting-panel block'>
            <div className='noti-setting-top'>
              <div className='noti-setting-bold'>
                {t('Notification.Activity summary email')}
              </div>
              <div>{t('Notification.activity summary email desc')}</div>
            </div>
            <div className='noti-setting-right'>
              <CustomSelect
                style={{ width: 300 }}
                dropdownMatchSelectWidth={true}
                defaultValue={userConfig && userConfig.notification_period}
                onChange={e => this.updateConfig(e, 'notification_period')}
              >
                {Feqs.map((feq, i) => (
                  <Option key={i} value={feq.key}>
                    {feq.title}
                  </Option>
                ))}
              </CustomSelect>
            </div>
          </div>
          <div className='noti-setting-panel block'>
            <div className='noti-setting-top'>
              <div className='noti-setting-bold'>
                {t('Notification.Connectivity events')}
              </div>
              <div>{t('Notification.Connectivity events desc')}</div>
            </div>
            <div className='noti-setting-right'>
              <CustomSelect
                style={{ width: 140 }}
                dropdownMatchSelectWidth={true}
                defaultValue={userConfig && userConfig.notification_channel_status}
                onChange={e =>
                  this.updateConfig(e, 'notification_channel_status')
                }
              >
                {Connectivity.map((con, i) => (
                  <Option key={i} value={con.key}>
                    {con.title}
                  </Option>
                ))}
              </CustomSelect>
            </div>
          </div>
          <div className='noti-setting-panel'>
            <div className='noti-setting-left'>
              <div className='noti-setting-bold'>
                {t('Notification.Conditional events')}
              </div>
              <div>{t('Notification.Conditional events desc')}</div>
            </div>
            <div className='noti-setting-right'>
              <CustomSelect
                style={{ width: 140 }}
                dropdownMatchSelectWidth={true}
                defaultValue={userConfig && userConfig.notification_channel_condition}
                onChange={e =>
                  this.updateConfig(e, 'notification_channel_condition')
                }
              >
                {BaseSetting.map((feq, i) => (
                  <Option key={i} value={feq.key}>
                    {feq.title}
                  </Option>
                ))}
              </CustomSelect>
            </div>
          </div>
          <div>
            <span className='small_text' style={{ marginRight: 10 }}>{`${t(
              'Type'
            )}`}</span>
            <Radio.Group
              value={radioValue}
              onChange={this.radioChange}
              size='small'
            >
              {category.map((v, i) => (
                <Radio.Button key={`notification-cate-${i}`} value={v.value}>
                  {v.label}
                </Radio.Button>
              ))}
            </Radio.Group>
            <br />
            <br />
          </div>
          <div className='noti-setting-table'>
            <Table
              type='light'
              columns={this.geneTable1Columns()}
              rowClassName={this.rowClassGen}
              dataSource={notificationTable.table}
              onRow={this.onRow1}
              size='default'
              pagination={false}
            />
          </div>
          {/* <div className='noti-setting-panel'>
            <div className='noti-setting-left'>
              <div className='noti-setting-bold'>
                {t('Notification.Event Subscription')}
              </div>
            </div>
          </div>
          <div className='noti-setting-table'>
            <SearchBar
              onSearch={this.onSearch}
              placeholder={t('Notification.Search by Workspace Name')}
            />
            <Table
              type='light'
              scroll={{ y: 200 }}
              pagination={pagination2}
              columns={this.geneTable2Columns()}
              dataSource={dataWs}
              onChange={this.handleTable2Change}
              size='default'
            />
          </div> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps: MapState<StateProps> = ({
  V3: { notificationConfig, userConfig, workspace }
}) => ({
  // notificationConfig: notificationConfig.data,
  notificationConfig,
  workspaces: workspace.list,
  userConfig: userConfig.data
  // query: notificationConfig.query,
  // meta: notificationConfig.meta
});

export default withRouter(connect(mapStateToProps)(NotificationSetting));
