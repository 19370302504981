import { Icon } from 'antd';
import React from 'react';
import { CSSTransition } from 'react-transition-group';

type P = {
  close: () => void;
  modalOpen: boolean;
  qrCode: string;
};

const ModalInfo: React.SFC<P> = React.memo((props) => {
  const { close, modalOpen, qrCode } = props;


  return (
    <CSSTransition classNames='appear' timeout={300} unmountOnExit={true} in={modalOpen === true}>
      <div className='qr-code-modal'>
        <div className='kiosk-page--close-btn' onClick={close}>
          <Icon type='close' theme='outlined' />
        </div>
        <div className='qr-code-modal-content'>
          <img src={qrCode} alt='QR code' width='100%' />
        </div>
      </div>
    </CSSTransition>
  );
});

export default ModalInfo;
