import { updateSiteState } from 'actions/uiState';
import {
  requestKioskEdit, requestKioskEditInfo, requestKioskNew,
  requestKiosks
} from 'actions/V3/kiosks';
import { requestV3Location } from 'actions/V3/location';
import { Col, Icon, message, Modal, Pagination, Row } from 'antd';
import SearchBar from 'components/SearchBar';
import Video from 'components/Video';
import { t } from 'i18next';
import indoor from 'images/Indoor Placeholder.png';
import template2 from 'images/kiosk/template-2.png';
import { pick } from 'lodash';
import qs from 'qs';
import * as React from 'react';
import Highlighter from 'react-highlight-words';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { promiseDispatch, uploadImage } from 'utils';
import { apiV3 } from 'utils/api';
import { Guard } from 'utils/guard';
import { queryMerge } from 'utils/query';
import AppThemeSetting from './AppThemeSetting';
import './index.css';
import { keys } from './kiosks';
import MockSetting from './MockSetting';
import NewKioskModal from './NewKioskModal';
import SettingModalContent from './SettingModalContent';
import { P, State, StateProps } from './type';


@Guard
class KioskList extends React.Component<P, State> {
  state: State = {
    confirmModalVisible: false,
    settingModalVisible: '',
    appThemeModalVisible: false,
    newKioskModalObj: undefined,
    newKioskModalStep: 1,
    currentTab: '1',
    notificationConfigs: [],
    indicatorList: []
  };


  nameInput;

  uploadAppLogo: any;
  uploadWebLogo: any;
  uploadAppBackground: any;
  uploadWebBackground: any;
  constructor(props: P) {
    super(props);
    this.uploadAppLogo = this.uploadLogo.bind(this, false);
    this.uploadWebLogo = this.uploadLogo.bind(this, true);
    this.uploadAppBackground = this.uploadBackground.bind(this, false);
    this.uploadWebBackground = this.uploadBackground.bind(this, true);
  }

  componentDidMount() {
    const {
      location: { search },
      locationID
    } = this.props;
    const firstElementRemoved = 1
    const query = pick(qs.parse(search.slice(firstElementRemoved)), ['page', 'name', 'id']);
    if (locationID) {
      query.id = String(locationID);
    }
    if (typeof query.page === 'string') {
      query.page = String(query.page);
    }
    this._requestKioskList(query);
    this._getWorkspaceAvailableTemplate();
  }

  _requestKioskList = (query = {}) => {
    const {
      dispatch,
      match: {
        params: { id }
      }
    } = this.props;
    dispatch(
      requestKiosks({
        ...query,
        client_id: +id,
        size: 12
      })
    );
  };

  _getWorkspaceAvailableTemplate = () => {
    const {
      match: {
        params: { id }
      }
    } = this.props;
    apiV3('/kiosks/available_templates', 'get', {
      workspace_id: id
    }).then((resp: any) => {
      this.setState({
        availableTemplate: resp.data.templates
      });
    });
  };

  _getUserNotificationSettings = () => {
    const {
      match: {
        params: { id }
      }
    } = this.props;
    const { currentKiosk } = this.state;

    if (
      currentKiosk &&
      currentKiosk.template_config &&
      currentKiosk.template_config.notification_user_id
    ) {
      const params = {
        user_id: currentKiosk.template_config.notification_user_id,
        workspace_id: id,
        location_id: currentKiosk.location_id,
        collection_id: currentKiosk.collection_id
      };
      apiV3('/notification_configs/configurable', 'get', params).then(
        (resp: any) => {
          this.setState({
            notificationConfigs: resp.data
          });
        }
      );
    }
  };

  handleOpenSetting = async (obj) => {
    const { dispatch } = this.props;
    const currentKiosk: any = await promiseDispatch({
      dispatch,
      actionCreator: requestKioskEditInfo,
      payload: {
        kiosk_id: obj.id
      }
    });
    this.setState(
      {
        currentKiosk,
        settingModalVisible: 'setting'
      },
      this._getUserNotificationSettings
    );
  };

  handleOpenAppThemeModal = (appKiosk) => {
    this.setState({
      currentKiosk: appKiosk,
      appThemeModalVisible: true
    });
  };

  handleCreateAppKiosk = async (location) => {
    const { dispatch } = this.props;
    await promiseDispatch({
      dispatch,
      actionCreator: requestKioskNew,
      payload: {
        location_id: location.id,
        screen_type: 1
      }
    });
  };

  GuardHandle_kioskAverage = (rule?: GuardRule) => rule && rule.enable;

  GuardHandle_OpenSetting = (rule?: GuardRule, message?: string) => ({
    rule,
    message
  });

  openSetting = (obj) => {
    const { rule, message: m } = this.GuardHandle_OpenSetting();
    if (!rule) {
      return message.warn(t('cannot use this feature'));
    }
    if (rule.enable) {
      return this.handleOpenSetting(obj);
    }
    if (rule.p) {
      return message.warn(`${t('can not use')} ${rule.name}`);
    }
    message.warn(m);
    return this.setState({ settingModalVisible: 'mock' });
  };

  movedDownSetState = (updatedValue) => {
    this.setState(updatedValue);
  };

  uploadBackground = (isWeb: boolean, { file }: any) => {
    uploadImage(file, 'background').then((observable) => {
      if (observable) {
        observable.subscribe(
          (next) => {
            /* next handle */
          },
          (err) => {
            message.error(t('upload Error', { message: err.message }));
          },
          ({ key: path }) => {
            if (this.state.currentKiosk) {
              this.setState(
                isWeb
                  ? {
                    background_image: `https://assets-1.qlear.io/${path}`,
                    currentKiosk: Object.assign({}, this.state.currentKiosk, {
                      background_image: path
                    })
                  }
                  : {
                    app_background_image: `https://assets-1.qlear.io/${path}`,
                    currentKiosk: Object.assign({}, this.state.currentKiosk, {
                      app_background_image: path
                    })
                  }
              );
            }
          }
        );
      }
    });
  };

  uploadLogo = (isWeb: boolean, { file }: any) => {
    uploadImage(file, 'logo').then((observable) => {
      if (observable) {
        observable.subscribe(
          (next) => {
            /* next handle */
          },
          (err) => {
            message.error(t('upload Error', { message: err.message }));
          },
          ({ key: path }) => {
            if (this.state.currentKiosk) {
              this.setState(
                isWeb
                  ? {
                    logo_image: `https://assets-1.qlear.io/${path}`,
                    currentKiosk: Object.assign(this.state.currentKiosk, {
                      logo_image: path
                    })
                  }
                  : {
                    app_logo_image: `https://assets-1.qlear.io/${path}`,
                    currentKiosk: Object.assign(this.state.currentKiosk, {
                      app_logo_image: path
                    })
                  }
              );
            }
          }
        );
      }
    });
  };

  handleSettingModalCancel = () => {
    this.setState({
      settingModalVisible: '',
      logo_image: undefined,
      app_logo_image: undefined,
      background_video: undefined,
      background_image: undefined,
      app_background_image: undefined,
      rm_background: undefined,
      rm_logo: undefined,
      currentKiosk: undefined,
      currentTab: '1'
    });
  };

  doSaveTemplate2 = async () => {
    const { dispatch } = this.props;
    const { currentKiosk } = this.state;
    await promiseDispatch({
      dispatch,
      actionCreator: requestKioskEdit,
      payload: {
        kiosk_id: currentKiosk!.id,
        data: pick(currentKiosk, [
          'template_key',
          'template_config',
          'locked',
          'password'
        ])
      }
    });
    this.handleSettingModalCancel();
  };

  //where the new settings are sent to
  doSave = async () => {
    const { dispatch } = this.props;
    const {
      currentKiosk,
      background_image,
      logo_image,
      rm_background,
      rm_logo,
      background_video
    } = this.state;
    if (!currentKiosk) {
      return;
    }
    if (rm_background) {
      currentKiosk.background_image = '';
    } else if (!background_image) {
      delete currentKiosk.background_image;
    }
    if (rm_logo) {
      currentKiosk.logo_image = '';
    } else if (!logo_image) {
      delete currentKiosk.logo_image;
    }
    if (background_video === undefined) {
      delete currentKiosk.background_video;
    } else {
      currentKiosk.background_video = background_video;
    }

    if (currentKiosk.screen_type === 'app') {
      if (currentKiosk!.id) {
        await promiseDispatch({
          dispatch,
          actionCreator: requestKioskEdit,
          payload: {
            kiosk_id: currentKiosk!.id,
            data: pick(currentKiosk, ['app_background_image', 'app_logo_image'])
          }
        });
      }
      this.setState({
        appThemeModalVisible: false
      });
    } else {
      const extraData = {
        average: this.packageDataForUpdate(currentKiosk.average)
      };
      for (const collection of currentKiosk.collections) {
        extraData[String(collection.id)] =
          this.packageDataForUpdate(collection);
      }
      if (currentKiosk!.id) {
        await promiseDispatch({
          dispatch,
          actionCreator: requestKioskEdit,
          payload: {
            kiosk_id: currentKiosk!.id,
            data: Object.assign(pick(currentKiosk, keys), {
              unit_config: extraData
            })
          }
        });
      }
      this.handleSettingModalCancel();
    }
  };

  packageDataForUpdate = (avg) => {
    if (!this.GuardHandle_kioskAverage() || !avg || !avg.data_channels) {
      return null;
    }
    const config = {};
    for (const dc of avg.data_channels) {
      config[dc.channel] = pick(dc, ['unit', 'hidden', 'formula']);
    }
    return Object.assign({}, pick(avg, ['hidden', 'primary_data_channel']), {
      config,
      positions: avg.data_channels.map((d) => d.channel)
    });
  };

  updateKioskData = (key, v) => {
    const { currentKiosk } = this.state;
    if (!currentKiosk) {
      return;
    }
    let value = v;
    if (currentKiosk[key] && typeof value === 'object') {
      value = Object.assign({}, currentKiosk[key], value);
    }
    this.setState({
      currentKiosk: Object.assign({}, currentKiosk, {
        [key]: value
      })
    });
  };

  onChangeRemoveImage = (key: 'rm_background' | 'rm_logo', v: boolean) => {
    const state: { rm_background?: boolean; rm_logo?: boolean } = {
      [key]: v
    };
    this.setState(state);
  };

  handleDisplayChannelChanged = (v) => {
    this.setState({
      currentKiosk: Object.assign({}, this.state.currentKiosk, {
        display_data_channels: v.join(',')
      })
    });
  };

  handleColorChange = (c) => {
    const { r, g, b, a } = c.rgb;
    const color = `rgba(${r},${g},${b},${a})`;
    this.updateKioskData('theme_color', color);
  };

  handleFontColorChange = (c) => {
    const { r, g, b, a } = c.rgb;
    const color = `rgba(${r},${g},${b},${a})`;
    this.updateKioskData('font_color', color);
  };

  navToKiosk = (obj) => {
    const {
      location: { pathname, search },
      dispatch,
      history
    } = this.props;

    dispatch(
      updateSiteState({
        originURL: pathname + search
      })
    );

    history.push('/' + obj.id);
  };

  doSearch = (text: string) => {
    const name = text.trim();
    const {
      location: { search, pathname },
      history
    } = this.props;
    const query = queryMerge({
      search,
      query: {
        name
      },
      delKeys: name ? ['page', 'size'] : ['page', 'size', 'name']
    });
    history.push({
      pathname,
      search: query
    });
    this._requestKioskList({ name });
  };

  selectPage = (page, size) => {
    const firstPage = 1
    const {
      location: { search, pathname },
      history,
      query: q
    } = this.props;
    const query = queryMerge({
      search,
      query: {
        page
      },
      delKeys: page === firstPage ? ['page'] : []
    });
    history.push({
      pathname,
      search: query
    });
    this._requestKioskList({ ...q, page, size });
  };

  renderHighlight = (text) => {
    const { query } = this.props;
    return query && (query as any).name ? (
      <Highlighter
        searchWords={[(query as any).name]}
        autoEscape={true}
        textToHighlight={text}
      />
    ) : (
      text
    );
  };

  Guard_AddKiosk = async (location_id) => {
    const { dispatch } = this.props;
    this.setState(
      {
        newKioskModalObj: {
          id: location_id,
          template: 'default'
        },
        newKioskModalStep: 1
      },
      () => {
        dispatch(
          requestV3Location({
            location_id
          })
        );
      }
    );
  };

  renderAppKioskCard = (location) => {
    const appKiosk = location.kiosk_list.find((k) => k.screen_type === 'app');
    return appKiosk ? (
      <div
        onClick={() => this.handleOpenAppThemeModal(appKiosk)}
        className='kiosk-panel--card'
        style={{
          backgroundImage: `url('${appKiosk.app_background_image
            ? appKiosk.app_background_image
            : indoor
            }')`
        }}
      >
        <div className='kiosk-panel--card-footer'>
          <span>{t('kiosk.app')}</span>

          <div className='kiosk-panel--card-menu'>
            <span className='material-icons location_detail-collection-more'>
              more_vert
            </span>
          </div>
        </div>
      </div>
    ) : (
      <div
        onClick={() => this.handleCreateAppKiosk(location)}
        className='kiosk-panel--card app-add-one'
      >
        <div className='app-title'>Theme of App</div>
        <p>设置app具体信息</p>
        <div className='app-button'>Activate</div>
      </div>
    );
  };

  renderKioskCardBg = (item) => {
    if (item.enable_background_video && item.background_video) {
      return {};
    } else if (item.template_key === 'fixed_panels') {
      return {
        backgroundImage: `url('${template2}')`
      };
    } else {
      return {
        backgroundImage: `url('${item.background_image ? item.background_image : indoor
          }')`
      };
    }
  };

  render() {
    const {
      kiosks,
      meta,
      location: { search }
    } = this.props;
    if (!meta) {
      return null;
    }
    const locationId = qs.parse(search.slice(1)).id;
    const {
      settingModalVisible,
      appThemeModalVisible,
      newKioskModalObj,
      currentKiosk,
      currentTab
    } = this.state;

    const { accounts, locationBase, dispatch, match } = this.props;
    return (
      <div className='kiosk-list-wrap'>
        <div className='kiosk-list-title'>{t('Kiosk List')}
        </div>
        <div className='kiosk-search-bar'>
          {!locationId && (<SearchBar
            onSearch={this.doSearch}
            filterIcon={true}
            placeholder={t('kiosk.Search by location name')}
          />)}
          {locationId && kiosks.map((location) => (
            <SearchBar
              onSearch={this.doSearch}
              defaultValue={locationId && location.name}
              filterIcon={true}
              placeholder={t('kiosk.Search by location name')}
              key={location.id}
            />))}
        </div>
        <div className='kiosk-panel--body'>
          {kiosks.map((location) => (
            <div
              className='kiosk-location-group'
              key={`location-group-${location.id}`}
            >
              <div className='kiosk-location-info'>
                {this.renderHighlight(location.name)}
              </div>
              <Row gutter={24} className='kiosk-location-kiosk-list'>
                <Col xs={{ span: 12 }} sm={{ span: 6 }} className='kiosk-panel-card-container'>{this.renderAppKioskCard(location)}</Col>
                {location.kiosk_list
                  .filter((k) => k.screen_type === 'web')
                  .map((item) => (
                    <React.Fragment key={`location-card-${item.id}`}>
                      <Col xs={{ span: 12 }} sm={{ span: 6 }} className='kiosk-panel-card-container'>
                        <div
                          key={item.id}
                          onClick={() => this.navToKiosk(item)}
                          className='kiosk-panel--card'
                          style={this.renderKioskCardBg(item)}
                        >
                          {item.enable_background_video &&
                            item.background_video && (
                              <div className='kiosk-panel-video'>
                                <Video
                                  size='small'
                                  url={item.background_video}
                                  autoPlay={false}
                                />
                              </div>
                            )}
                          <div className='kiosk-panel--card-footer'>
                            <span>{item.name || t('kiosk.default')}</span>

                            <div className='kiosk-panel--card-menu'>
                              <a
                                className='material-icons location_detail-collection-more'
                                onClick={(e) => {
                                  e.stopPropagation();
                                  this.openSetting(item);
                                }}
                              >
                                more_vert
                              </a>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </React.Fragment>
                  ))}
                {location.kiosk_list.filter((k) => k.screen_type === 'web')
                  .length < 3 && (
                    <Col span={6} className='kiosk-panel-add-one-container'>
                      <div
                        className='kiosk-panel--card add-one'
                        onClick={() => this.Guard_AddKiosk(location.id)}
                      >
                        <Icon type='plus' />
                      </div>
                    </Col>
                  )}
              </Row>
            </div>
          ))}
        </div>
        {meta && (
          <Pagination
            hideOnSinglePage={true}
            showQuickJumper={true}
            onChange={this.selectPage}
            style={{ padding: '20px 0', textAlign: 'right' }}
            current={meta.current_page}
            defaultPageSize={12}
            total={meta.total_count}
          />
        )}
        <Modal
          title={t('kiosk.settings')}
          closable={true}
          centered={true}
          footer={null}
          width={648}
          maskClosable={false}
          visible={!!settingModalVisible}
          onCancel={this.handleSettingModalCancel}
          bodyStyle={{
            padding: '0 30px 24px 30px'
          }}
          wrapClassName='kiosk-setting-modal'
        >
          {settingModalVisible === 'setting' && (
            <SettingModalContent
              movedDownSetState={this.movedDownSetState}
              updateKioskData={this.updateKioskData}
              GuardHandle_OpenSetting={this.GuardHandle_OpenSetting}
              GuardHandle_kioskAverage={this.GuardHandle_kioskAverage}
              handleSettingModalCancel={this.handleSettingModalCancel}
              _getUserNotificationSettings={this._getUserNotificationSettings}
              doSave={this.doSave}
              doSaveTemplate2={this.doSaveTemplate2}
              dispatch={dispatch}
              uploadWebBackground={this.uploadWebBackground}
              uploadWebLogo={this.uploadWebLogo}
              onChangeRemoveImage={this.onChangeRemoveImage}
              handleColorChange={this.handleColorChange}
              handleFontColorChange={this.handleFontColorChange}
              match={match}
              meta={meta}
              accounts={accounts}
              currentKiosk={currentKiosk}
              currentTab={currentTab}
              state={this.state}
            />
          )}
          {settingModalVisible === 'mock' && (
            <MockSetting
              close={() => this.setState({ settingModalVisible: '' })}
            />
          )}
        </Modal>
        <Modal
          title={t('kiosk.settings')}
          closable={true}
          centered={true}
          footer={null}
          maskClosable={false}
          visible={appThemeModalVisible}
          onCancel={() => {
            this.setState({
              appThemeModalVisible: false
            });
          }}
          bodyStyle={{ padding: '0 30px 24px 30px' }}
          wrapClassName='kiosk-setting-modal'
        >
          <AppThemeSetting
            state={this.state}
            currentKiosk={currentKiosk}
            uploadAppBackground={this.uploadAppBackground}
            uploadAppLogo={this.uploadAppLogo}
            movedDownSetState={this.movedDownSetState}
            doSave={this.doSave}
          />
        </Modal>
        <Modal
          title={this.state.newKioskModalStep === 3 ? '' : t('kiosk.add new')}
          closable={true}
          centered={true}
          footer={null}
          maskClosable={false}
          visible={!!newKioskModalObj}
          onCancel={() => {
            this.setState({
              newKioskModalObj: undefined
            });
          }}
          bodyStyle={{ padding: '0 30px 24px 30px' }}
          wrapClassName='kiosk-setting-modal add-kiosk'
        >
          {newKioskModalObj && (
            <NewKioskModal
              state={this.state}
              openSetting={this.openSetting}
              locationBase={locationBase}
              newKioskModalObj={newKioskModalObj}
              nameInput={this.nameInput}
              dispatch={dispatch}
              movedDownSetState={this.movedDownSetState}
            />
          )}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps: MapState<StateProps> = ({
  V3: { kiosks, location, collections },
  V2: { accounts },
  uiState: {
    siteState: { locationID }
  }
}) => ({
  kiosks: kiosks.data,
  meta: kiosks.meta,
  accounts: accounts.data,
  collections: collections.list,
  query: kiosks.query,
  locationID,
  locationBase: location.base
});

export default withRouter(connect(mapStateToProps)(KioskList));
