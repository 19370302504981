import { requestAccounts } from 'actions/V2/accounts'
import { CollapsePanelinfo, requestKioskDelete } from 'actions/V3/kiosks'
import { WorkspaceObj } from 'actions/V3/workspace'
import { Button, Collapse, Input, Popconfirm, Radio, Switch, Tabs } from 'antd'
import { t } from 'i18next'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { promiseDispatch } from 'utils'
import InfoModal from '../../pages/Kiosk/InfoModal'
import { SettingModalContentSize } from '../../utils/enums'
import Collapsedpannel from './Collapsedpannel'
import SettingModalContentT2 from './SettingModalContentT2'
import ThemeSetting from './ThemeSetting'
const TabPane = Tabs.TabPane
const Panel = Collapse.Panel

interface StateProps {
  workspace?: WorkspaceObj;
}

type Props = StateProps & {
  movedDownSetState,
  updateKioskData,
  GuardHandle_OpenSetting,
  GuardHandle_kioskAverage,
  handleSettingModalCancel,
  _getUserNotificationSettings,
  doSave,
  doSaveTemplate2,
  dispatch,
  uploadWebBackground,
  uploadWebLogo,
  onChangeRemoveImage,
  handleColorChange,
  handleFontColorChange,
  match,
  meta,
  accounts,
  currentKiosk,
  currentTab,
  state,
}

function SettingModalContent({
  workspace,
  movedDownSetState,
  updateKioskData,
  GuardHandle_OpenSetting,
  GuardHandle_kioskAverage,
  handleSettingModalCancel,
  _getUserNotificationSettings,
  doSave,
  doSaveTemplate2,
  dispatch,
  uploadWebBackground,
  uploadWebLogo,
  onChangeRemoveImage,
  handleColorChange,
  handleFontColorChange,
  match,
  meta,
  accounts,
  currentKiosk,
  currentTab,
  state

}: Props) {
  const [tab, setTab] = useState(currentTab)
  const [modalContent, setModalContent] = React.useState<'location_information' | 'kiosk_information'>('location_information');
  const [modalOpen, setModalOpen] = React.useState(false);
  const StandardSize: SettingModalContentSize = 100
  const setUnitType = (e: any) => {
    movedDownSetState({
      currentKiosk: Object.assign({}, currentKiosk, {
        use_aqi: e.target.value === 'b'
      })
    })
  }
  const WorkspaceAccounts = (size: any) => {
    dispatch(
      requestAccounts({
        size,
        workspace_id: match.params.id
      })
    )
  }
  const renderCollapsePanel = (obj: any, isAverage: boolean, useAqi: any, template_key: any, meta: any) => {
    const { data_channels, name, id } = obj

    return (
      <Panel
        header={isAverage ? t('Location Average') : name}
        key={id || 'average'}
        extra={
          <div className='kiosk-collection-hidden'>
            <span className='desc'>{t('Visible')}</span>
            <Switch defaultChecked={!obj.hidden} onClick={(v, e) => e.stopPropagation()} onChange={v => (obj.hidden = !v)} />
          </div>
        }
      >
        <Collapsedpannel
          template_key={template_key}
          obj={obj}
          currentKiosk={currentKiosk}
          useAqi={useAqi}
          data_channels={data_channels}
          meta={meta}
        />
      </Panel>
    )
  }

  const handleDeleteKiosk = async ({ id, location_id }) => {
    await promiseDispatch({
      dispatch,
      actionCreator: requestKioskDelete,
      payload: {
        kiosk_id: id,
        location_id: location_id
      }
    })
    handleSettingModalCancel()
  }

  const GuardRender_publicSetting = () => {
    if (!currentKiosk) {
      return <div>{t('all locations has kiosks')}</div>
    }

    return (
      <div className='location_detail-setting-row is-working_hours'>
        <div className='location_detail-setting-row_left'>
          <div className='label'>{t('locationOverview.Locked')}</div>
          <p className='small_text'>{t('locationOverview.if locked')}</p>
          {currentKiosk.locked && (
            <div style={{ marginTop: 16 }}>
              <p className='register-group-label'>{t('password')}</p>
              <Input value={currentKiosk.password} onChange={e => updateKioskData('password', e.target.value)} />
            </div>
          )}
        </div>

        <div className='location_detail-setting-row_right'>
          <Switch checked={currentKiosk.locked} onChange={v => updateKioskData('locked', v)} />
        </div>
      </div>
    )
  }

  if (!currentKiosk) {
    return <div>{t('all locations has kiosks')} </div>
  }

  if (currentKiosk.template_key === 'fixed_panels') {
    if (!accounts.length) {
      WorkspaceAccounts(StandardSize)
    }
    return (
      <SettingModalContentT2
        currentKiosk={currentKiosk}
        currentTab={currentTab}
        notificationConfigs={state.notificationConfigs}
        movedDownSetState={movedDownSetState}
        accounts={accounts}
        _getUserNotificationSettings={_getUserNotificationSettings}
        GuardRender_publicSetting={GuardRender_publicSetting}
        handleDeleteKiosk={handleDeleteKiosk}
        handleSettingModalCancel={handleSettingModalCancel}
        doSaveTemplate2={doSaveTemplate2}
      />
    )
  }

  const r = GuardHandle_OpenSetting()

  const openModal = (modalContent) => {
    setModalContent(modalContent)
    setModalOpen(true);
  };

  return (
    <div className='body'>
      {(currentKiosk.id || (r.rule && r.rule.enable)) && (
        <Tabs
          defaultActiveKey='1'
          onChange={key => {
            setTab(key)
          }}
          animated={{ inkBar: true, tabPane: false }}
        >
          <TabPane tab={t('kiosk.tab content')} key='1'>
            <div className='kiosk-container-top'>
              <div className='unit-of-type'>
                <span className='label'>{t('Units of Type')}:</span>
                <Radio.Group onChange={setUnitType} size='small' defaultValue={currentKiosk.use_aqi ? 'b' : 'a'} buttonStyle='solid'>
                  <Radio.Button value='a'>{t('kiosk.Concentration')}</Radio.Button>
                  <Radio.Button value='b'>AQI</Radio.Button>
                </Radio.Group>
                <div className='help-hint'>({t('Units of Type hint')})</div>
              </div>
            </div>
            <div className='historical-data-limit-container'>
              <div className='label-container'>
                <div className='label-historical-data-limit'>{t('kiosk.historical data limit')}</div>
                <div className='help-hint'>({t('kiosk.historical data limit hint')})</div>
              </div>
              <Input size='small'
                suffix='days'
                type='number'
                placeholder={workspace?.historical_data_limit?.toString()}
                className='historical-data-limit-input'
                value={currentKiosk.historical_data_limit}
                onChange={(e) => updateKioskData('historical_data_limit', e.target.value)}
                onKeyUp={(e) => {
                  const value = parseInt(e.currentTarget.value, 10);
                  const maxLimit = workspace?.historical_data_limit || 180;
                  if (value < 1) {
                    updateKioskData('historical_data_limit', 1);
                  } else if (value > maxLimit) {
                    updateKioskData('historical_data_limit', maxLimit);
                  }
                }}
              />
            </div>
            <div className='kiosk-modal-information-container'>
              <Button className='kiosk-modal-child' size='small' type='link' onClick={() => openModal('location_information')}>{t('kiosk.edit location information')}</Button>
              <Button className='kiosk-modal-child' size='small' type='link' onClick={() => openModal('kiosk_information')}>{t('kiosk.edit kiosk information')}</Button>
            </div>

            <Collapse accordion={true} bordered={false} defaultActiveKey={['average']}>
              {GuardHandle_kioskAverage() &&
                renderCollapsePanel(
                  //need to update currentKiosk to display new titles
                  currentKiosk.average,
                  true,
                  currentKiosk.use_aqi,
                  currentKiosk.template_key,
                  meta
                )}
              {currentKiosk.collections.map((collection: CollapsePanelinfo) =>
                renderCollapsePanel(collection, false, currentKiosk.use_aqi, currentKiosk.template_key, meta)
              )}
            </Collapse>
          </TabPane>
          <TabPane tab={t('kiosk.tab display option')} key='2'>
            <div className='display-option-container'>
              <div className='display-option-container-row'>
                <div >
                  <div className='label'>{t('kiosk.show qr code')}</div>
                </div>
                <div className='display-option-container-row-right'>
                  <span className='desc'>{t('Visible')}</span>
                  <Switch
                    checked={currentKiosk.show_qr_code}
                    onChange={(v) => updateKioskData('show_qr_code', v)}
                  />
                </div>
              </div>
              <div className='display-option-container-row'>
                <div >
                  <div className='label'>{t('kiosk.show health')}</div>
                </div>
                <div className='display-option-container-row-right'>
                  <span className='desc'>{t('Visible')}</span>
                  <Switch
                    checked={currentKiosk.show_health_rating}
                    onChange={(v) => updateKioskData('show_health_rating', v)}
                  />
                </div>
              </div>
              <div className='display-option-container-row'>
                <div >
                  <div className='label'>{t('kiosk.download button')}</div>
                </div>
                <div className='display-option-container-row-right'>
                  <span className='desc'>{t('Visible')}</span>
                  <Switch
                    checked={currentKiosk.show_download_button}
                    onChange={(v) => updateKioskData('show_download_button', v)}
                  />
                </div>
              </div>
              <div className='display-option-container-row'>
                <div >
                  <div className='label'>{t('kiosk.notification_button')}</div>
                </div>
                <div className='display-option-container-row-right'>
                  <span className='desc'>{t('Visible')}</span>
                  <Switch
                    checked={currentKiosk.show_notification_button}
                    onChange={(v) => updateKioskData('show_notification_button', v)}
                  />
                </div>
              </div>
              <div className='display-option-container-row'>
                <div >
                  <div className='label'>{t('kiosk.menu contact us')}</div>
                </div>
                <div className='display-option-container-row-right'>
                  <span className='desc'>{t('Visible')}</span>
                  <Switch
                    checked={currentKiosk.show_menu_contact_us}
                    onChange={(v) => updateKioskData('show_menu_contact_us', v)}
                  />
                </div>
              </div>
              <div className='display-option-container-row'>
                <div>
                  <div className='label'>{t('kiosk.menu profile')}</div>
                </div>
                <div className='display-option-container-row-right'>
                  <span className='desc'>{t('Visible')}</span>
                  <Switch
                    checked={currentKiosk.show_menu_profile}
                    onChange={(v) => updateKioskData('show_menu_profile', v)}
                  />
                </div>
              </div>
              <div className='display-option-container-row'>
                <div >
                  <div className='label'>{t('kiosk.menu more')}</div>
                </div>
                <div className='display-option-container-row-right'>
                  <span className='desc'>{t('Visible')}</span>
                  <Switch
                    checked={currentKiosk.show_menu_more}
                    onChange={(v) => updateKioskData('show_menu_more', v)}
                  />
                </div>
              </div>
              <div className='display-option-container-row'>
                <div>
                  <div className='label'>{t('kiosk.enable home button')}</div>
                </div>
                <div className='display-option-container-row-right'>
                  <span className='desc'>{t('kiosk.allow')}</span>
                  <Switch
                    checked={currentKiosk.enable_home_button}
                    onChange={(v) => updateKioskData('enable_home_button', v)}
                  />
                </div>
              </div>
            </div>

          </TabPane>
          <TabPane tab={t('kiosk.tab web theme')} key='3'>
            <ThemeSetting
              state={state}
              updateKioskData={updateKioskData}
              movedDownSetState={movedDownSetState}
              uploadWebBackground={uploadWebBackground}
              onChangeRemoveImage={onChangeRemoveImage}
              uploadWebLogo={uploadWebLogo}
              handleColorChange={handleColorChange}
              handleFontColorChange={handleFontColorChange}
            />
          </TabPane>
          <TabPane tab={t('kiosk.tab public')} key='4'>
            {GuardRender_publicSetting()}
          </TabPane>
          <TabPane tab={t('kiosk.tab delete')} key='5'>
            <div className='body'>
              <div className='tab-title'>{t('kiosk.tab delete')}</div>
              <p className='tab-desc'>{t('kiosk.tab delete content')}</p>
            </div>
          </TabPane>
        </Tabs>
      )}
      {tab === '4' ? (
        <div className='footer'>
          <Popconfirm
            title='Are you sure delete this kiosk?'
            onConfirm={() => {
              handleDeleteKiosk(currentKiosk)
            }}
            okText='Yes'
            cancelText='No'
          >
            <Button type='danger'>{t('kiosk.tab delete')}</Button>
          </Popconfirm>
        </div>
      ) : (
        <div className='footer'>
          <Button type='primary' ghost={true} onClick={handleSettingModalCancel}>
            {t('Cancel')}
          </Button>
          <Button type='primary' onClick={doSave}>
            {t('Save')}
          </Button>
        </div>
      )}

      {modalOpen && (
        <InfoModal
          close={() => setModalOpen(false)}
          kiosk={currentKiosk}
          modalOpen={modalOpen}
          edition={true}
          modalContent={modalContent}
          updateKioskData={updateKioskData}
        />
      )}

    </div>
  )
}

const mapStateToProps = (state: any): StateProps => ({
  workspace: state.V3.workspace.data
});

export default connect(mapStateToProps)(SettingModalContent);
